// Start script which should be as small as possible and execute first, render-blocking.
// This includes scripts which heavily influence the page content or the UI.

document.documentElement.classList.remove('no-js')

// https://github.com/ePages-de/epages-storage/blob/v0.10.12/src/universal/conf/application.conf#L61
const maxStorageDimension = 2560

window.lazySizesConfig = {
  rias: {
    // Allowed widths (= RIaS plugin default value reduced to the epages-storage maximum dimension)
    widths: [180, 360, 540, 720, 900, 1080, 1296, 1512, 1728, 1944, 2160, 2376, maxStorageDimension],

    modifyOptions: function (data) {
      const maxWidth = data.target.getAttribute('width')

      if (maxWidth) {
        // Constrain "widths" up to the maximum width of an image.
        const widths = data.detail.widths.filter((width) => width <= maxWidth)

        // Ensure that the maximum width (or original width) is included in the list.
        if (maxWidth < maxStorageDimension && Math.max.apply(null, widths) < maxWidth) {
          widths.push(+maxWidth)
        }

        data.detail.widths = widths
      }
    },
  },
}

// Load in-view images as fast as possible:
// Most users start to interact with a page after in-view images are loaded.
require('lazysizes/plugins/attrchange/ls.attrchange')
require('lazysizes/plugins/rias/ls.rias')
require('lazysizes')

// Load shim for the `:focus-visible` CSS pseudo-class:
require('focus-visible')
